import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"

import { Container } from "react-bootstrap"
import { renderRichText } from "../lib/renderRichText"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"

const PrivacyPage = props => {
  const { contentfulImpressum } = props.data
  const text = documentToPlainTextString(contentfulImpressum.impressumText.json)
  return (
    <>
      <SEO
        title={contentfulImpressum.title}
        lang={contentfulImpressum.node_locale}
        description={text.slice(0, 120)}
      />

      <Container fluid className="pt-4">
        <Container className="p-5 paper">
          <h1 className="font-weight-bold">{contentfulImpressum.title}</h1>
          <div className="lead">
            {renderRichText(contentfulImpressum.impressumText.json)}
          </div>
        </Container>
      </Container>
    </>
  )
}

export const pageQuery = graphql`
  query imprintPage($locale: String!) {
    contentfulImpressum(
      pagetitle: { eq: "imprint" }
      node_locale: { eq: $locale }
    ) {
      impressumText {
        json
      }
      node_locale
      title
    }
  }
`
export default PrivacyPage
